<template>
	<div style="height: 100%;">
		<template v-if="theme.is_mobile && theme.width < 750">
			<div class="head">
				<div>{{params.page}} / {{Math.ceil(params.total/params.perPage)}}，共 {{params.total}} 条记录</div>
				<div @click="popup_filter = true">筛选</div>
			</div>
			<div class="wrap">
				<div style="margin-bottom: 10px; padding: 10px; background-color: #fff;" v-for="objective in rates" :key="objective.id">
					<div>{{objective.no}}</div>
					<div v-if="admin_user.shop_id">{{objective | format_department(oa_users, oa_departments)}}</div>
					<div>{{objective | format_user(oa_users)}}</div>
					<div v-if="objective.created_at">{{objective.created_at}}</div>
				</div>
			</div>
			<van-pagination v-model="params.page" :total-items="params.total" :items-per-page="params.perPage" @change="(v) => { getContractsFees({ ...params, page: v }) }"></van-pagination>
		</template>
		<main-table v-else>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-select v-model="params.goods_type_id" placeholder="请选择类型" filterable clearable>
						<el-option v-for="type in goods_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getPerformancesRates({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="rates" :size="theme.size">
				<el-table-column label="业务岗位" prop="shop_position.name">
					<el-link slot-scope="scope" type="primary" :disabled="!$utils.update('positions')" @click="$refs['show_dialog'].open(scope.row.shop_position)">{{scope.row.shop_position.name}}</el-link>
				</el-table-column>
				<el-table-column label="固定工资" :formatter="({shop_position}) => { return (shop_position.base_wage+shop_position.post_wage)/100+' 元'; }"></el-table-column>
				<el-table-column label="产品类型" prop="goods_type.name"></el-table-column>
				<el-table-column label="绩效提点" :formatter="({ratio}) => { return ratio/100+' %'; }"></el-table-column>
				<el-table-column label="更新时间" prop="updated_at" min-width="180"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" min-width="180"></el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getPerformancesRates({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getPerformancesRates({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<show-position ref="show_dialog" @refresh="getPerformancesRates(params)"></show-position>
	</div>
</template>

<style>
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
	.mobile .head {
		display: flex; line-height: 45px; color: #666; font-size: 14px; border-bottom: 1px solid #ccc; padding: 0 10px; justify-content: space-between; background-color: #fff;
	}
	.mobile .wrap {
		flex: 1; background-color: #f5f5f5; overflow: auto;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import showPosition from '@/pages/hrm/positions/show';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable,
			showPosition
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			async getPerformancesRates (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_PERFORMANCES_RATES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.rates = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				rates: [],
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getPerformancesRates(this.params, true);
		}
	};
</script>