<template>
	<el-dialog width="460px" :title="position.id ? '编辑职位' : '创建职位'" :visible.sync="show_dialog" :fullscreen="theme.width < 680" :close-on-click-modal="false" append-to-body destroy-on-close>
		<el-tabs style="margin-top: -30px;" v-model="tab_active" v-if="show_dialog">
			<el-tab-pane label="基本信息" name="basic">
				<el-form label-width="80px" ref="position_form" :model="position" :rules="position_rules" :size="theme.size" status-icon>
					<el-form-item label="名称" prop="name">
						<el-input v-model="position.name" placeholder="请输入名称" clearable></el-input>
					</el-form-item>
					<el-form-item label="基本工资" prop="base_wage">
						<el-input-number v-model="position.base_wage" :min="1" :max="9999999" placeholder="基本工资"></el-input-number>
					</el-form-item>
					<el-form-item label="岗位工资" prop="post_wage">
						<el-input-number v-model="position.post_wage" :min="0" :max="9999999" placeholder="岗位工资"></el-input-number>
						<p style="font-size: 12px; color: #999;">注意：工资单位为【分】。合计 {{(position.base_wage + position.post_wage)/100 || 0}} 元</p>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('position_form')">{{position.id ? '提交保存' : '提交创建'}}</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="业务提点" name="rates" v-if="position.id" lazy>
				<el-table class="scroll-wrapper" height="46vh" size="mini" :data="rates">
					<el-table-column label="产品类型" prop="goods_type.name"></el-table-column>
					<el-table-column label="绩效提点" prop="ratio">
						<el-link slot-scope="scope" type="primary" :disabled="!$utils.update('performances/rates') || scope.row.deleted_at" @click="rate = {...scope.row, ratio: scope.row.ratio/100}; rates_dialog = true;">{{scope.row.ratio/100}} %</el-link>
					</el-table-column>
					<el-table-column label="操作时间"  min-width="180">
						<template slot-scope="scope">
							<p>更新 {{scope.row.updated_at}}</p>
							<p>创建 {{scope.row.created_at}}</p>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="60">
						<el-button slot="header" type="text" size="mini" @click="rate = {shop_position_id: position.id}; rates_dialog = true;" :disabled="!$utils.create('performances/rates')">添加</el-button>
						<template slot-scope="scope">
							<el-popconfirm title="确定恢复这个提点吗？" @confirm="handleRates({action: 'restore', id: scope.row.id})" v-if="scope.row.deleted_at">
								<el-button slot="reference" type="text" :disabled="!$utils.restore('performances/rates')">恢复</el-button>
							</el-popconfirm>
							<el-popconfirm title="确定删除这个提点吗？" @confirm="handleRates({action: 'delete', id: scope.row.id})" v-else>
								<el-button slot="reference" type="text" :disabled="!$utils.delete('performances/rates')">删除</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="params.perPage"
					:current-page="params.page"
					:total="params.total"
					@size-change="(v) => { getPerformancesRates({ ...params, page: 1, perPage: v }) }"
					@current-change="(v) => { getPerformancesRates({ ...params, page: v }) }"
					background>
				</el-pagination>
			</el-tab-pane>
		</el-tabs>
		<el-dialog :title="rate.id ? '编辑提成' : '创建提成'" width="380px" :visible.sync="rates_dialog" @closed="rate = {}" append-to-body destroy-on-close>
			<el-form label-width="60px" ref="rate_form" :model="rate" :rules="rate_rules" :size="theme.size" status-icon>
				<el-form-item label="类型" prop="goods_type_id">
					<el-select v-model="rate.goods_type_id" placeholder="请选择类型" filterable clearable>
						<el-option v-for="type in goods_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="提点" prop="ratio">
					<el-input-number v-model="rate.ratio" :precision="2" :step="0.01" :min="0.01" :max="655.35" label="提点"></el-input-number>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('rate_form')">确 定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme', 'goods']),
			goods_types () {
				return this.goods.goods_types || [];
			}
		},
		filters: {

		},
		methods: {
			async open (r) {
				this.tab_active = 'basic';
				this.position = {...r};
				this.rates = [];
				if (r && r.id) {
					this.getPerformancesRates({...this.params, position_id: r.id});
				}
				this.show_dialog = true;
			},
			async handleRates (params) {
				const res = await this.$http.get(this.$api.URI_PERFORMANCES_RATES, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getPerformancesRates(this.params);
				this.$message.success(msg);
			},
			async getPerformancesRates (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_PERFORMANCES_RATES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.rates = result.data;
				this.shops = result.shops;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async submitRates (data) {
				const res = await this.$http.post(this.$api.URI_PERFORMANCES_RATES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getPerformancesRates({...this.params, page: 1});
						this.$refs['rate_form'].resetFields();
						this.rates_dialog = false;
					}
				});
			},
			async submitPositions (data) {
				const res = await this.$http.post(this.$api.URI_POSITIONS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
						this.show_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'position_form':
							this.submitPositions(this.position);
						break;
						case 'rate_form':
							this.submitRates(this.rate);
						break;
					}
				});
			}
		},
		data() {
			return {
				rate: {},
				rates: [],
				position: {},
				tab_active: 'basic',
				show_dialog: false,
				rates_dialog: false,
				params: {
					perPage: 10
				},
				position_rules: {
					name: [{ required: true, message: '必须填写名称', trigger: 'blur'}],
					base_wage: [{ required: true, message: '必须填写基本工资', trigger: 'blur'}],
					post_wage: [{ required: true, message: '必须填写职位工资', trigger: 'blur'}],
				},
				rate_rules: {
					ratio: [{ required: true, message: '必须填写提点', trigger: 'blur'}],
					goods_type_id: [{ required: true, message: '必须选择类型', trigger: 'blur'}]
				}
			}
		}
	};
</script>